import React from "react"

import "./resources-icon.css"

const ResourcesIcon = () => (
  <div className="icon resources-icon">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 130.27 130.27"
      style={{ enableBackground: "new 0 0 130.27 130.27" }}
    >
      <rect class="temporary-dwellings-fill" width="130.27" height="130.27" />
    </svg>
    <div>Resources</div>
  </div>
)

export default ResourcesIcon
